import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { apiService } from "../services/apiservices";
import Swal from "sweetalert2";
const ChangePassword = () => {
  const navigate = useNavigate()
  const [data, setData] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword:false,
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({});
  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "password":
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          error = "Password is required.";
        } else if (trimmedValue.length < 8) {
          error = "Password must be at least 8 characters long.";
        } else if (
          !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_+={[}\|:;"'<,>.?/]).*$/.test(
            trimmedValue
          )
        ) {
          error =
            "Password must include at least 1 letter, 1 number, and 1 special character.";
        }
        break;
      case "confirmPassword":
        const trimmedpass = value.trim();
        if (trimmedpass !== data.password) {
          error = "Confirm password does not match the password";
        }
        break;
      default:
        break;
    }
    return error;
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value.trim() }));
    const error = validateInput(name, value);
    console.log("=====error====", error)
    setErrors({ ...errors, [name]: error });
    if (name == "password") {
      if (value === data.confirmPassword) {
        setErrors({ ...errors, confirmPassword: "" })
      }
      else if (value !== data.confirmPassword && data.confirmPassword) {
        setErrors({ ...errors, confirmPassword: "Confirm password does not match the password" })
      }
    }
  }; const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(errors);
    if (data.currentPassword.trim() == "") {
      setErrors({ ...errors, currentPassword: "Current Password is required." })
      return
    }
    if (data.password.trim() == "") {
      setErrors({ ...errors, password: "Password is required." })
      return
    }
    if (data.confirmPassword.trim() == "") {
      setErrors({ ...errors, confirmPassword: "Confirm Password is required." })
      return
    }
    if (data.password !== data.confirmPassword) {
      setErrors({ ...errors, confirmPassword: "Confirm password does not match the password" })
      return
    }
    if (data.password === data.confirmPassword) {
      setErrors({ ...errors, password: "", confirmPassword: "" })
      const params = {
        oldPassword: data.currentPassword.trim(),
        newPassword: data.password.trim(),
      };
      console.log("=====params===", params)
      //   return
      try {
        // setShowLoader(true);
        const response = await apiService.changePassword(params);
        if (response.status >= 200 && response.status <= 299) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Password changed successfully. Please login again.",
          }).then((result) => {
            if (result.isConfirmed) {
              // Perform the desired action here
              localStorage.clear()

              navigate('/')
            }
          });


          //   setShow(true);
          setData({ ...data, password: "", confirmPassword: "" });
        }
        // setShowLoader(false);
      } catch (error) {
        console.log(error);
        // setShowLoader(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
      }
    }
  };
  return (
    <>
      <section className="common-area">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={7} xl={6}>
              <div className="change-password-inner">
                <h2>Change Password</h2>
                <Form>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Current Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control type={showPassword.currentPassword?"text":"password"} placeholder="Enter Current Password"
                        name="currentPassword"
                        value={data.currentPassword}
                        onChange={handleInputChange}
                      />
                      {!showPassword.currentPassword?
                      <i class="fa-solid fa-eye"        onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          currentPassword: !showPassword.currentPassword,
                        });
                      }}></i>:
                      <i class="fa-solid fa-eye-slash"        onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          currentPassword: !showPassword.currentPassword,
                        });
                      }}></i>}
                    </div>


                    {errors.currentPassword && (
                      <span className="text-danger">
                        {errors.currentPassword}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control type={showPassword.password?"text":"password"} placeholder="Enter New Password"
                        name="password"
                        value={data.password}
                        onChange={handleInputChange}
                      />
                      {!showPassword.password?
                      <i class="fa-solid fa-eye"
                      onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        });
                      }}
                      ></i>:
                       <i class="fa-solid fa-eye-slash"
                       onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        });
                      }}
                       ></i> }
                    </div>
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Confirm New Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control type={showPassword.confirmPassword?"text":"password"} placeholder="Enter New Password"
                        name="confirmPassword"
                        value={data.confirmPassword}
                        onChange={handleInputChange}
                      />
                      {!showPassword.confirmPassword?
                      <i class="fa-solid fa-eye"
                       onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        });
                      }}
                      ></i>
                      : <i class="fa-solid fa-eye-slash"
                         onClick={() => {
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        });
                      }}
                      ></i> }
                    </div>
                    {errors.confirmPassword && (
                      <span className="text-danger">
                        {errors.confirmPassword}
                      </span>
                    )}
                  </Form.Group>
                  <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Change Password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ChangePassword;