import React, { useState } from 'react';
import axios from 'axios';
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { apiService } from '../services/apiservices';
import Swal from 'sweetalert2';

const EmailSender = () => {
    // const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [selectedType, setSelectedType] = useState('creator');

    const handleSendEmails = async () => {
        if (!subject || !content) {
            alert('Please fill in all fields.');
            return;
        }

        Swal.fire({
            icon: "info",
            title: "Sending Emails...",
            text: "Please wait while we send your emails.",
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
        
        const params = {
            // title,
            subject,
            content,
            role: selectedType
        };

        try {
            const response = await apiService.sendMassMails(params);
            console.log('Emails sent successfully!', response);
                Swal.fire({
                    icon: "success",
                    title: "Mass email has been sent successfully.",
                    text : "It may take a few minutes for the emails to reach the users."
                })
            
            
            // setTitle('');
            setSubject('');
            setContent('');
            setSelectedType('creator');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <section className="common-area">
            <Container fluid>
                <div className="email-sender-container">
                    <h2>Email Sender</h2>
                    <div className="form d-flex flex-column shadow p-4 rounded gap-3 w-50 mt-3">
                        {/* <div>
                <label>Title:</label>
                <input 
                    type="text" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter email title"
                    className="form-control"
                />
            </div> */}

                        <div>
                            <label>Subject:</label>
                            <input
                                type="text"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                placeholder="Enter email subject"
                                className="form-control"
                            />
                        </div>

                        <div className="radio-group">
                            <label>Send To:</label>
                            <div>
                                <input
                                    type="radio"
                                    name="type"
                                    value="creator"
                                    checked={selectedType === 'creator'}
                                    onChange={() => setSelectedType('creator')}
                                /> Creators
                                <input
                                    type="radio"
                                    name="type"
                                    value="brand"
                                    checked={selectedType === 'brand'}
                                    onChange={() => setSelectedType('brand')}
                                    style={{ marginLeft: '20px' }}
                                /> Brands
                            </div>
                        </div>

                        {/* <div>
                <label>Mail Body:</label>
                <input
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Enter email body"
                    className="form-control"
                    style={{ height: "190px" }}
                />
            </div> */}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Mail Body:</Form.Label>
                            <Form.Control as="textarea" rows={5} placeholder='Enter email body' value={content} onChange={(e) => setContent(e.target.value)} />
                        </Form.Group>

                        <Button className='submit-btn w-50 py-2 rounded-pill' onClick={handleSendEmails}>Send Emails</Button>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default EmailSender;
