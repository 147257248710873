import axios from 'axios';
import { formDataHeader, formDataHeaderToken, jsonHeaders, jsonHeadersToken } from '../helpers';
import { config } from '../config/config';


const configFormDataHeaders = ()=>{
    return {headers: formDataHeader()}
}

const formDataToken = ()=>{
   return {headers: formDataHeaderToken()}
}

const configJsonHeaders =()=> {
   return {headers: jsonHeaders()}
}

const jsonHeadersTokenHeader= ()=>{
    return {
        headers: jsonHeadersToken()
    }
}

export const apiService = {
    adminLogin,
    getUser,
    getPendingList,
    userApproval,
    getLandingPageList,
    getCountData,
    getChartData,
    changePassword,
    sendMassMails 
};


function adminLogin (params){
    return axios.post(`${config.apiUrl}/admin/admin-login`,params,configJsonHeaders())
}

function getUser (userType,page,searchKey){
    return axios.get(`${config.apiUrl}/admin/getUsers?page=${page}&role=${userType}&searchKey=${searchKey}`,jsonHeadersTokenHeader())
}

function getPendingList (userType,page=1){
    return axios.get(`${config.apiUrl}/admin/get-pending-list?page=${page}&role=${userType}`,jsonHeadersTokenHeader())
}

function userApproval(params){
    return axios.post(`${config.apiUrl}/admin/approve-reject`,params,jsonHeadersTokenHeader())
}

function getLandingPageList(userType,page=1,searchKey){
    return axios.get(`${config.apiUrl}/admin/get-landing-page-list?page=${page}&role=${userType}&searchKey=${searchKey}`,jsonHeadersTokenHeader())
}

function getCountData(){
    return axios.get(`${config.apiUrl}/admin/dashingBoard`,jsonHeadersTokenHeader())
}

function getChartData(){
    return axios.get(`${config.apiUrl}/admin/weeklyData`,jsonHeadersTokenHeader())
}

function changePassword(params){
    return axios.post(`${config.apiUrl}/admin/change-password`,params,jsonHeadersTokenHeader())
}

function sendMassMails(params){
    return axios.post(`${config.apiUrl}/admin/send-emails`,params,jsonHeadersTokenHeader())
}